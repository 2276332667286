import React from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import {
  FaChevronLeft,
  FaChevronRight,
  FaCheck,
  FaPencilAlt,
} from "react-icons/fa";
import { BsGraphUpArrow } from "react-icons/bs";
import { GrSchedules } from "react-icons/gr";
import { MdDelete } from "react-icons/md";
import { IoCopy, IoAddOutline } from "react-icons/io5";
import { AiOutlineStop } from "react-icons/ai";

const OrderDetailModal = ({
  orderDetailModal,
  handleCloseOrderDetailModal,
}) => {
  return (
    <>
      <Modal
        show={orderDetailModal}
        onHide={handleCloseOrderDetailModal}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="order__modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Order Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center justify-content-between">
            <div className="prev__btn">
              <button type="button" className="btn btn-primary">
                <FaChevronLeft />
              </button>
            </div>
            <div className="modal__btn__list d-flex align-items-center gap-2">
              <div>
                <button type="button" className="btn btn-primary">
                  <BsGraphUpArrow /> Delivery Time Window
                </button>
              </div>
              <div>
                <button type="button" className="btn btn-primary">
                  <BsGraphUpArrow /> VMI
                </button>
              </div>
              <div>
                <button type="button" className="btn btn-primary">
                  <GrSchedules /> Schedule
                </button>
              </div>
              <div>
                <button type="button" className="btn btn-primary">
                  <MdDelete /> Delete
                </button>
              </div>
              <div>
                <button type="button" className="btn btn-primary">
                  <IoCopy /> Duplicate{" "}
                  <input
                    type="number"
                    className="px-1 rounded-1 border-0"
                    style={{ width: "70px" }}
                  />
                </button>
              </div>
            </div>
            <div className="next__btn">
              <button type="button" className="btn btn-primary">
                <FaChevronRight />
              </button>
            </div>
          </div>
          <div className="d-flex align-items-center gap-4 my-3">
            <div>
              <p className="fw-medium">
                Customer{" "}
                <a href="/" className="text-decoration-none">
                  NEELUM VALLEY FILLING STATION
                </a>
              </p>
            </div>
            <div>
              <p className="fw-medium">
                ACS Number <span className="fw-light">12345678</span>
              </p>
            </div>
            <div>
              <p className="fw-medium">
                Ship To <span className="fw-light">12345678</span>
              </p>
            </div>
            <div>
              <p className="fw-medium">
                Type <span className="fw-light">NON-VMI</span>
              </p>
            </div>
            <div>
              <p className="fw-medium">
                Location-Priority <span className="fw-light">9</span>
              </p>
            </div>
          </div>
          <div className="d-flex align-items-center gap-4 my-3">
            <div className="flex-fill">
              <Form.Label>Order Priority</Form.Label>
              <Form.Select className="w-100">
                <option value="">1</option>
              </Form.Select>
            </div>
            <div className="flex-fill">
              <Form.Label>Order Status</Form.Label>
              <Form.Select className="w-100">
                <option value="">Possible</option>
              </Form.Select>
            </div>
            <div className="flex-fill">
              <Form.Label>Order Reason</Form.Label>
              <Form.Select className="w-100">
                <option value="">Select status reason</option>
              </Form.Select>
            </div>
            <div className="flex-fill">
              <Form.Label>Scope</Form.Label>
              <Form.Select className="w-100">
                <option value="">Chaklala</option>
              </Form.Select>
            </div>
            <div className="flex-fill">
              <p className="fw-medium">
                Scheduling Status <span className="fw-light">Un Schedule</span>
              </p>
            </div>
          </div>
          <div className="d-flex align-items-end gap-4 my-3">
            <div className="flex-fill">
              <Form.Label>ACS Special Instruction</Form.Label>
              <Form.Control as="textarea" style={{ height: "80px" }} />
            </div>
            <div className="flex-fill">
              <Form.Label>Delivery Instruction</Form.Label>
              <Form.Control as="textarea" style={{ height: "80px" }} />
            </div>
            <div className="flex-fill text-end">
              <button type="button" className="btn btn-primary me-1">
                <FaCheck /> Save
              </button>
              <button type="button" className="btn btn-secondary">
                <AiOutlineStop /> Cancel
              </button>
            </div>
          </div>
          <hr />
          <div className="d-flex align-items-end gap-4 my-3">
            <div className="order__table flex-fill">
              <h6>First Time Window</h6>
              <table className="table">
                <thead>
                  <tr>
                    <th>Start Day</th>
                    <th>Start Date</th>
                    <th>End Day</th>
                    <th>End Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>THU</td>
                    <td>14/3/2024 00:01</td>
                    <td>THU</td>
                    <td>14/3/2024 00:01</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="order__table flex-fill">
              <h6>Last Time Window</h6>
              <table className="table">
                <thead>
                  <tr>
                    <th>Start Day</th>
                    <th>Start Date</th>
                    <th>End Day</th>
                    <th>End Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>THU</td>
                    <td>14/3/2024 00:01</td>
                    <td>THU</td>
                    <td>14/3/2024 00:01</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="d-flex align-items-end gap-4 my-3">
            <div className="flex-fill">
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    Active Time Window - Start:
                    <span className="text-primary d-inline ms-1">
                      THU - 14/3/2024 00:01
                    </span>
                    - End:{" "}
                    <span className="text-primary d-inline ms-1">
                      THU - 14/3/2024 00:01
                    </span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="order__table">
                      <table className="table">
                        <thead>
                          <tr>
                            <th></th>
                            <th>Start Day</th>
                            <th>Start Date</th>
                            <th>Start Time</th>
                            <th>End Day</th>
                            <th>End Date</th>
                            <th>End Time</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <div>
                                <button
                                  type="button"
                                  className="btn btn-primary me-2"
                                >
                                  <FaPencilAlt />
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-secondary me-2"
                                >
                                  <MdDelete />
                                </button>
                              </div>
                            </td>
                            <td>THU</td>
                            <td>14/3/2024</td>
                            <td>00:01</td>
                            <td>THU</td>
                            <td>14/3/2024</td>
                            <td>00:01</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
          <div className="d-flex align-items-end gap-4 my-3">
            <div className="order__table flex-fill">
              <h6>Tank Details</h6>
              <div className="order__table__header d-flex align-items-center justify-content-between">
                <div>
                  <button type="button" className="btn btn-primary me-2">
                    <IoAddOutline /> Add New Record
                  </button>
                  <button type="button" className="btn btn-primary me-2">
                    <FaCheck /> Save Changes
                  </button>
                  <button type="button" className="btn btn-secondary">
                    <AiOutlineStop /> Cancel Changes
                  </button>
                </div>
                <div className="order__table__txt">
                  <span>
                    Please click on save changes after delete order item/s
                  </span>
                </div>
              </div>
              <table className="table">
                <thead>
                  <tr>
                    <th></th>
                    <th>Product-Tank</th>
                    <th>Min</th>
                    <th>Target</th>
                    <th>Max</th>
                    <th>HitDeadStock</th>
                    <th>HitBufferStock</th>
                    <th className="p-0">
                      <div className="table__lg__col__head">
                        <span>Expected</span>
                      </div>
                      <div className="table__lg__col">
                        <div>
                          <span>Sale</span>
                        </div>
                        <div>
                          <span>Stock</span>
                        </div>
                      </div>
                    </th>
                    <th>Min Drop</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <button type="button" className="btn btn-primary">
                        <MdDelete />
                      </button>
                    </td>
                    <td>-1234567-HDC C</td>
                    <td>4750</td>
                    <td>5000</td>
                    <td>5000</td>
                    <td></td>
                    <td></td>
                    <td className="p-0">
                      <div className="table__lg__col">
                        <div>
                          <span>0</span>
                        </div>
                        <div>
                          <span>0</span>
                        </div>
                      </div>
                    </td>
                    <td>0</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseOrderDetailModal}>
            Close
          </Button>
          <Button variant="primary">Understood</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default OrderDetailModal;
