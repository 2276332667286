import React from "react";
import { Col, Row, Form } from "react-bootstrap";

const TracktorPropertiesTab = () => {
  return (
    <>
      <div className="d-flex align-items-center gap-4">
        <div>
          <Form.Label>Code</Form.Label>
          <Form.Control type="text" />
        </div>
        <div>
          <Form.Label>Registration Number</Form.Label>
          <Form.Control type="text" />
        </div>
      </div>
      <hr />
      <Row>
        <Col
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          className="border-end border-dark-subtle"
        >
          <div className="address__form">
            <h4 className="mb-4">Tracktor Details</h4>
            <form action="">
              <Row className="row-gap-3">
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className="form__input">
                    <Form.Label>Weight (WGt)</Form.Label>
                    <Form.Control type="text" />
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className="form__input">
                    <Form.Label>Scope</Form.Label>
                    <Form.Select>
                      <option value=""></option>
                    </Form.Select>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className="form__input">
                    <Form.Label>Discharge Factor</Form.Label>
                    <Form.Control type="text" />
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className="form__input">
                    <Form.Label>Expiry Date</Form.Label>
                    <Form.Control type="text" />
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className="form__input">
                    <Form.Label>Parking Location </Form.Label>
                    <Form.Select>
                      <option value=""></option>
                    </Form.Select>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className="text-end">
                    <button className="btn btn-primary" type="submit">
                      Save
                    </button>
                  </div>
                </Col>
              </Row>
            </form>
          </div>
        </Col>
        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
          <div className="address__form">
            <h4 className="mb-4">User Filter Field</h4>
            <form action="">
              <Row className="row-gap-3">
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className="form__input">
                    <Form.Label>Field 1</Form.Label>
                    <Form.Control type="text" />
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className="form__input">
                    <Form.Label>Field 2</Form.Label>
                    <Form.Control type="text" />
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className="form__input">
                    <Form.Label>Field 3</Form.Label>
                    <Form.Control type="text" />
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className="form__input">
                    <Form.Label>Field 4</Form.Label>
                    <Form.Control type="text" />
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className="form__input">
                    <Form.Label>Field 5 </Form.Label>
                    <Form.Control type="text" />
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className="form__input">
                    <Form.Label>Field 1 </Form.Label>
                    <Form.Control as="textarea" style={{ height: "80px" }} />
                  </div>
                </Col>
              </Row>
            </form>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default TracktorPropertiesTab;
