import React from "react";
import "../../styles/dashboard.scss";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import FullCalendar from "@fullcalendar/react";
import { BsGripVertical } from "react-icons/bs";
import { Nav, Tab } from "react-bootstrap";
import { FaShoppingCart, FaGripLines } from "react-icons/fa";
import { FaFileImport  } from "react-icons/fa6";
import {  IoIosSend } from "react-icons/io";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { PiSelectionAllBold } from "react-icons/pi";
import { MdFilterAlt } from "react-icons/md";
import OrderTableTab from "../../components/OrderTableTab";
import ImportTab from "../../components/ImportTab";
import SendTab from "../../components/SendTab";
import TsdTab from "../../components/TsdTab";
import TTab from "../../components/TTab";
import VTab from "../../components/VTab";

function Dashboard() {
  
  const renderEventContent = (eventInfo) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0 10px",
        }}
      >
        <span>{eventInfo.event.title}</span>
        <span
          style={{ marginLeft: "auto", paddingLeft: "10px", color: "#fff" }}
        >
          {eventInfo.event.extendedProps.title2}
        </span>
      </div>
    );
  };
  return (
    <div className="dashboard">
      
      <PanelGroup direction="horizontal">
        {/* Left Pane: FullCalendar */}
        <Panel defaultSize={50} minSize={0}>
          <div
            className="calendar__view"
            style={{ padding: "0 0 0 0", overflow: "auto" }}
          >
            <Tab.Container id="left-tabs-example" defaultActiveKey="p-tab">
              <div className="calendar__topbar">
                <Nav variant="pills" className="justify-content-start">
                  <Nav.Item>
                    <Nav.Link eventKey="p-tab">P</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="t-tab">T</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="v-tab">V</Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item>
                    <Nav.Link eventKey="n-tab">N</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="c-tab">C</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="d-tab">D</Nav.Link>
                  </Nav.Item> */}
                </Nav>
                {/* <div>
                  <input type="text" placeholder="0.000" />
                </div>
                <div>
                  <Form.Select name="" id="">
                    <option value="">--TRIP ACTION--</option>
                    <option value="">--TRIP ACTION--</option>
                    <option value="">--TRIP ACTION--</option>
                    <option value="">--TRIP ACTION--</option>
                  </Form.Select>
                </div> */}
                <div className="calendar__topbar__btn">
                  <button className="btn btn-primary" type="button">
                  <PiSelectionAllBold />
                  </button>
                </div>
                <div className="calendar__topbar__btn">
                  <button className="btn btn-primary" type="button">
                  <MdFilterAlt />
                  </button>
                </div>
              </div>
              <Tab.Content>
                <Tab.Pane eventKey="p-tab">
                  <FullCalendar
                    plugins={[resourceTimelinePlugin]}
                    initialView="resourceTimeline"
                    resources={[
                      { id: "a", title: "Room A" },
                      { id: "b", title: "Room B" },
                    ]}
                    events={[
                      {
                        id: "1",
                        resourceId: "a",
                        title: "Long Event",
                        title2: "Second Title",
                        start: "2024-11-04T02:00:00",
                        end: "2024-11-05T20:00:00",
                      },
                      {
                        id: "2",
                        resourceId: "b",
                        title: "Short Event",
                        title2: "Different Title",
                        start: "2024-11-04T01:00:00",
                        end: "2024-11-05T10:00:00",
                      },
                    ]}
                    visibleRange={{
                      start: "2024-11-04",
                      end: "2024-11-06",
                    }}
                    slotDuration="01:00:00"
                    weekends={true}
                    headerToolbar={{
                      left: "prev,next today",
                      center: "title",
                      right: "resourceTimelineDay,resourceTimelineMonth",
                    }}
                    resourceAreaWidth="100px"
                    eventContent={renderEventContent} // Use the custom render function here
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="t-tab">
                  <TTab />
                </Tab.Pane>
                <Tab.Pane eventKey="v-tab">
                  <VTab />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </Panel>

        {/* Resize Handle */}
        <PanelResizeHandle className="resize-handle">
          <BsGripVertical />
          <BsGripVertical />
        </PanelResizeHandle>

        {/* Right Pane: Data Table */}
        <Panel defaultSize={50} minSize={0}>
          <div style={{ padding: "0 10px 0 0 ", overflow: "auto" }}>
            <div className="table-tabs">
              <Tab.Container id="left-tabs-example" defaultActiveKey="orders">
                <Nav variant="pills" className="justify-content-start">
                  <Nav.Item>
                    <Nav.Link eventKey="orders">
                      Orders <FaShoppingCart />
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="import">
                      Import <FaFileImport />
                    </Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item>
                    <Nav.Link eventKey="generate">
                      Generate <IoIosSettings />
                    </Nav.Link>
                  </Nav.Item> */}
                  <Nav.Item>
                    <Nav.Link eventKey="send">
                      Send <IoIosSend />
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="tsd">
                      TSD <FaGripLines />
                    </Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item>
                    <Nav.Link eventKey="note">
                      Note <FaStickyNote />
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="kpi">
                      KPI <GoGraph />
                    </Nav.Link>
                  </Nav.Item> */}
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="orders">
                    <OrderTableTab />
                  </Tab.Pane>
                  <Tab.Pane eventKey="import">
                    <ImportTab />
                  </Tab.Pane>
                  <Tab.Pane eventKey="send">
                    <SendTab />
                  </Tab.Pane>
                  <Tab.Pane eventKey="tsd">
                    <TsdTab />
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        </Panel>
      </PanelGroup>
    </div>
  );
}

export default Dashboard;
