import React, { useState, useMemo } from "react";
import DataTable from "react-data-table-component";
import { IoIosAdd } from "react-icons/io";
import AddSalesModal from "../modals/AddSalesModal";

const fakeUsers = [
  {
    id: 1,
    s_no: "1",
    start_day: "Monday",
    start_time: "11:47am",
    end: "Tuesday",
    end_time: "10:00am",
    edit: "Edit",
  },
];

// Define columns
const columns = [
  {
    name: "S.No",
    selector: (row) => row.s_no,
    sortable: true,
  },
  {
    name: "Start Day",
    selector: (row) => row.start_day,
    sortable: true,
  },
  {
    name: "Start Time",
    selector: (row) => row.start_time,
  },
  {
    name: "End Day",
    selector: (row) => row.end,
    sortable: true,
  },
  {
    name: "End Time",
    selector: (row) => row.end_time,
    sortable: true,
  },
  {
    name: "Edit",
    selector: (row) => row.edit,
    sortable: true,
  },
];

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
    <input
      type="text"
      placeholder="Search..."
      value={filterText}
      onChange={onFilter}
      style={{ padding: "5px", fontSize: "14px" }}
    />
    <button onClick={onClear} className="btn btn-primary">
      Clear
    </button>
  </div>
);

const ParkingSalesTab = () => {
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [addSalesModal, setAddTimingModal] = useState(false);

  const handleCloseSalesModal = () => setAddTimingModal(false);
  const handleShowSalesModal = () => setAddTimingModal(true);

  // Filter the data based on search input
  const filteredItems = fakeUsers.filter(
    (item) =>
      item.start_day && item.start_day.toLowerCase().includes(filterText.toLowerCase())
  );

  // Memoize the sub-header component
  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <>
      <div className="address__table">
        <div className="table__modal__btn mb-3">
          <button
            className="btn btn-primary"
            type="button"
            onClick={handleShowSalesModal}
          >
            <IoIosAdd /> Add Sales
          </button>
        </div>
        <DataTable
          columns={columns}
          data={filteredItems}
          pagination
          paginationResetDefaultPage={resetPaginationToggle} // Reset pagination to page 1
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          selectableRows
          persistTableHead
        />
      </div>
      {addSalesModal && (
        <AddSalesModal
          handleCloseSalesModal={handleCloseSalesModal}
          addSalesModal={addSalesModal}
        />
      )}
    </>
  );
};

export default ParkingSalesTab;
