import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { FaFileAlt, FaTimesCircle } from "react-icons/fa";
import { RiFileSettingsFill } from "react-icons/ri";
import { FileUploader } from "react-drag-drop-files";
import DataTable from "react-data-table-component";
import axios from "axios";
// import { useScopeDetails } from "../hooks/useScopeDetails";

// helper function to decode orders import
import { decodeOrders, formatDate } from "../helpers/helper";

import OrderDetailModal from "./modals/OrderDetailModal";
import AcsImportModal from "./modals/AcsImportModal";

const ImportTab = () => {
  const [orderDetailModal, setOrderDetailModal] = useState(false);
  const [importModal, setImportModal] = useState(false);
  const [file, setFile] = useState(null);

  const [decodedOrders, setDecodedOrders] = useState([]);
  const fileTypes = ["TXT"];

  const handleFileRead = (uploadedFile) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const text = event.target.result;
      processFileContent(text);
    };
    reader.readAsText(uploadedFile);
  };

  const onSubmitFile = (file) => {
    if (file) {
      const fileName = file.name;
      const index = fileName.indexOf("ORDERS_0");
      if (index !== -1) {
        const extractedNumber = fileName.slice(index + 8, index + 11);
        console.log("Extracted Number: ", extractedNumber);

        // var settings = {
        //   url: "scope_detail/" + extractedNumber,
        //   method: "GET",
        //   timeout: 0,
        // };
      } // index if ends
    } //parent if ends
  };

  const processFileContent = (text) => {
    const decodedData = decodeOrders(text);
    setDecodedOrders(decodedData);
    console.log("Decoded Data: ", decodedData);
  };

  const handleFileUpload = (uploadedFile) => {
    setFile(uploadedFile);
    handleFileRead(uploadedFile);
    onSubmitFile(uploadedFile);
  };

  const resetFile = () => {
    setFile(null);
    setDecodedOrders([]);
  };

  const handleShowOrderDetailModal = () => setOrderDetailModal(true);
  const handleCloseOrderDetailModal = () => setOrderDetailModal(false);

  const handleShowImportModal = () => setImportModal(true);
  const handleCloseImportModal = () => setImportModal(false);

  const columns = [
    {
      name: "Order Id",
      selector: (row) => row.orderId,
      sortable: true,
      width: "150px",
    },
    { name: "Ship To", selector: (row) => row.shipTo, sortable: true },
    {
      name: "Customer Name",
      selector: (row) => row.customerName,
      sortable: true,
      width: "300px",
    },
    {
      name: "Order Date",
      selector: (row) => formatDate(row.date),
      sortable: true,
      width: "200px",
    },
    { name: "Product", selector: (row) => row.product, sortable: true },
    { name: "Target", selector: (row) => row.target, sortable: true },
    {
      name: "Vehicle Access",
      selector: (row) => row.vehicleAccess,
      sortable: true,
    },
    { name: "Status", selector: (row) => row.status, sortable: true },
    { name: "City", selector: (row) => row.city, sortable: true },
  ];

  return (
    <div className="import__tab">
      <div className="import__inner__tab">
        <div className="vmi__tab_wrapper" style={{ padding: "10px" }}>
          <div className="heading">
            <h3>VMI DATA</h3>
          </div>

          {/* Action Buttons */}
          <div
            className="d-flex align-items-center gap-3"
            style={{ padding: "10px 0" }}
          >
            <button
              className="btn btn-primary"
              onClick={handleShowOrderDetailModal}
            >
              <FaFileAlt /> Load VMI Data
            </button>
            <Form.Select style={{ width: "200px" }}>
              <option value="">VMI PKF</option>
            </Form.Select>
            <button className="btn btn-primary" onClick={handleShowImportModal}>
              <RiFileSettingsFill /> Set Default
            </button>
          </div>

          {/* File Upload */}
          <div className="file__upload text-center" style={{ padding: "10px" }}>
            {!file ? (
              <FileUploader
                handleChange={handleFileUpload}
                name="file"
                types={fileTypes}
                multiple={false}
              />
            ) : (
              <div className="d-flex align-items-center gap-3">
                <FaFileAlt size={24} style={{ color: "blue" }} />
                <span>{file.name}</span>
                <FaTimesCircle
                  size={24}
                  style={{ color: "red", cursor: "pointer" }}
                  onClick={resetFile}
                />
              </div>
            )}
          </div>

          {/* Table */}
          {decodedOrders.length > 0 && (
            <div className="import__table">
              <DataTable
                title="VMI Data Table"
                columns={columns}
                data={decodedOrders}
                pagination
                highlightOnHover
                fixedHeader
                fixedHeaderScrollHeight="400px"
                subHeader
                dense
                subHeaderComponent={
                  <input
                    type="text"
                    placeholder="Search..."
                    style={{
                      width: "200px",
                      padding: "8px",
                      fontSize: "1rem",
                      marginBottom: "10px",
                    }}
                  />
                }
              />
            </div>
          )}
        </div>
      </div>

      {/* Modals */}
      {orderDetailModal && (
        <OrderDetailModal
          orderDetailModal={orderDetailModal}
          handleCloseOrderDetailModal={handleCloseOrderDetailModal}
        />
      )}
      {importModal && (
        <AcsImportModal
          importModal={importModal}
          handleCloseImportModal={handleCloseImportModal}
        />
      )}
    </div>
  );
};

export default ImportTab;
