import React, { useState, useMemo } from "react";
import CustomerTopbar from "../../../components/CustomerTopbar";
import "../../../styles/pages.scss";
import { Col, Row, Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { IoIosAdd } from "react-icons/io";
import AddContactModal from "../../../components/modals/AddContactModal";

const fakeUsers = [
  {
    id: 1,
    s_no: "1",
    name: "John Doe",
    contactType: "Active",
    details: "Random",
    active: "Active",
    edit: "Edit",
    delete: "Delete",
  },
  {
    id: 1,
    s_no: "1",
    name: "John Doe",
    contactType: "Active",
    details: "Random",
    active: "Active",
    edit: "Edit",
    delete: "Delete",
  },
  {
    id: 1,
    s_no: "1",
    name: "John Doe",
    contactType: "Active",
    details: "Random",
    active: "Active",
    edit: "Edit",
    delete: "Delete",
  },
  {
    id: 1,
    s_no: "1",
    name: "John Doe",
    contactType: "Active",
    details: "Random",
    active: "Active",
    edit: "Edit",
    delete: "Delete",
  },
];

// Define columns
const columns = [
  {
    name: "S.No",
    selector: (row) => row.name,
    sortable: true,
  },
  {
    name: "Name",
    selector: (row) => row.name,
    sortable: true,
  },
  {
    name: "Contact Type",
    selector: (row) => row.contactType,
  },
  {
    name: "Details",
    selector: (row) => row.details,
    sortable: true,
  },
  {
    name: "Active",
    selector: (row) => row.active,
    sortable: true,
  },
  {
    name: "Edit",
    selector: (row) => row.edit,
    sortable: true,
  },
  {
    name: "Delete",
    selector: (row) => row.delete,
    sortable: true,
  },
];

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
    <input
      type="text"
      placeholder="Search..."
      value={filterText}
      onChange={onFilter}
      style={{ padding: "5px", fontSize: "14px" }}
    />
    <button onClick={onClear} className="btn btn-primary">
      Clear
    </button>
  </div>
);

const CustomerAddress = () => {
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [addContactModal, setAddContactModal] = useState(false);

  const handleCloseContactModal = () => setAddContactModal(false);
  const handleShowContactModal = () => setAddContactModal(true);

  // Filter the data based on search input
  const filteredItems = fakeUsers.filter(
    (item) =>
      item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
  );

  // Memoize the sub-header component
  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const customerRoutes = [
    { navLink: "/customer-address", linkName: "Address" },
    { navLink: "/customer-tanks", linkName: "Tanks" },
    { navLink: "/customer-timings", linkName: "Timings" },
  ];

  return (
    <div className="customer__wrp">
      <CustomerTopbar customerRoutes={customerRoutes} />
      <Row>
        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
          <div className="address__wrp">
            <div className="address__form">
              <form action="">
                <Row className="row-gap-3">
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Form.Label>Customer Name</Form.Label>
                    <Form.Control type="text" />
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Form.Label>Post Code</Form.Label>
                    <Form.Control type="text" />
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Form.Label>Country Code</Form.Label>
                    <Form.Control type="text" />
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Form.Label>GSAP Code</Form.Label>
                    <Form.Control type="text" />
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Form.Label>Street</Form.Label>
                    <Form.Control type="text" />
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Form.Label>Latitude</Form.Label>
                    <Form.Control type="text" />
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Form.Label>Longitude</Form.Label>
                    <Form.Control type="text" />
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Form.Label>Short Code</Form.Label>
                    <Form.Control type="text" />
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Form.Label>LatLong</Form.Label>
                    <Form.Control type="text" />
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </Col>
                </Row>
              </form>
            </div>
            <div className="address__table">
              <div className="table__modal__btn mb-3 mt-4">
                <button className="btn btn-primary" type="button" onClick={handleShowContactModal}>
                  <IoIosAdd /> Add Contact
                </button>
              </div>
              <DataTable
                columns={columns}
                data={filteredItems}
                pagination
                paginationResetDefaultPage={resetPaginationToggle} // Reset pagination to page 1
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                selectableRows
                persistTableHead
              />
            </div>
          </div>
        </Col>
        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d14479.264026557226!2d67.0793728!3d24.87013295!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2s!4v1731676575339!5m2!1sen!2s"
            width="600"
            height="450"
            style={{ border: "0" }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </Col>
      </Row>
      {addContactModal && (
        <AddContactModal
          addContactModal={addContactModal}
          handleCloseContactModal={handleCloseContactModal}
        />
      )}
    </div>
  );
};

export default CustomerAddress;
