import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const AddCompartmentModal = ({
  compartmentModal,
  handleCloseCompartmentModal,
}) => {
  return (
    <>
      <form action="">
        <Modal
          show={compartmentModal}
          onHide={handleCloseCompartmentModal}
          backdrop="static"
          keyboard={false}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="timing__mdoal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Compartment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="row-gap-3 align-items-end">
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Form.Label>Compartment No</Form.Label>
                <Form.Control type="text" />
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Form.Label>Loadable Volume</Form.Label>
                <Form.Control type="text" />
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Form.Label>Hazardous Volume</Form.Label>
                <Form.Control type="text" />
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Check label="Must Use Indicator" type="checkbox" />
                <Form.Check label="Manifold Valve Indicator" type="checkbox" />
                <Form.Check label="Must Not Split" type="checkbox" />
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Check label="Bulk Load Indicator" type="checkbox" />
                <Form.Check label="Part Loading Indicator" type="checkbox" />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseCompartmentModal}>
              Close
            </Button>
            <Button variant="primary">Save Changes</Button>
          </Modal.Footer>
        </Modal>
      </form>
    </>
  );
};

export default AddCompartmentModal;
