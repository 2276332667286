import React from "react";
import CustomerTopbar from "../../../components/CustomerTopbar";
import { Nav, Tab } from "react-bootstrap";
import TracktorPropertiesTab from "../../../components/vehicleTabs/TracktorPropertiesTab";
import TracktorTsdTab from "../../../components/vehicleTabs/TracktorTsdTab";

const Tracktor = () => {
  const customerRoutes = [
    { navLink: "/vehicles", linkName: "Vehicles" },
    { navLink: "/properties", linkName: "Properties" },
    { navLink: "/tracktor", linkName: "Tracktor" },
    { navLink: "/trailer", linkName: "Trailer" },
    { navLink: "/groups", linkName: "Groups" },
  ];
  return (
    <div className="customer__wrp">
      <CustomerTopbar customerRoutes={customerRoutes} />
      <div className="address__wrp">
        <div className="customer__tab">
          <Tab.Container id="left-tabs-example" defaultActiveKey="properties">
            <Nav variant="pills" className="justify-content-start">
              <Nav.Item>
                <Nav.Link eventKey="properties">Properties</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="tsd-default">TSD Default</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="properties">
                <div className="tab__content">
                  <TracktorPropertiesTab />
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="tsd-default">
                <div className="tab__content">
                  <TracktorTsdTab />
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
    </div>
  );
};

export default Tracktor;
