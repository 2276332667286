// components/AuthLayout.js
import React from "react";
import { Navigate } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import TopBar from "../components/TopBar";

function AuthLayout({ children }) {
  const isAuthenticated = localStorage.getItem("authToken"); // Replace with your authentication logic

  if (!isAuthenticated) {
    // Redirect to login if not authenticated
    return <Navigate to="/" />;
  }

  return (
    <main>
      {/* Place any layout components here, like navbars or sidebars */}
      <TopBar />
      <div className="d-flex">
        <aside>
          <Sidebar />
        </aside>
        {children}
      </div>
    </main>
  );
}

export default AuthLayout;