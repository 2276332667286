import React, { useState } from "react";
import "../styles/topBar.scss";
import { Form } from "react-bootstrap";
import { CiCalendarDate } from "react-icons/ci";
import { FaPlus } from "react-icons/fa";
import { IoIosNotifications } from "react-icons/io";
import { MdLogout } from "react-icons/md";
import FilterModal from "./modals/FilterModal";
import OrderModal from "./modals/OrderModal";

const TopBar = () => {
  const [filterModal, setFilterModal] = useState(false);
  const [orderModal, setOrderModal] = useState(false);

  const handleCloseFilterModal = () => setFilterModal(false);
  const handleShowFilterModal = () => setFilterModal(true);

  const handleCloseOrderModal = () => setOrderModal(false);
  const handleShowOrderModal = () => setOrderModal(true);
  // const [scopesList, setScopesList] = useState(null);

  // const fetchScopes = () => {

  // }

  return (
    <div className="topbar">
      <div className="topbar__logo">
        <img src="https://via.placeholder.com/50" alt="Logo" />
      </div>
      <div className="topbar__menu">
        <ul>
          <li>
            <Form.Select
              className="select-code"
              aria-label="Default select example"
            >
              <option>Code</option>
              <option value="1">PKF</option>
              <option value="2">CKF</option>
              <option value="3">PER</option>
            </Form.Select>
          </li>
          <li>
            <Form.Select aria-label="Default select example">
              <option>City</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </Form.Select>
          </li>
          <li>
            <Form.Control
              id="date"
              type="date"
              aria-label="date"
              aria-describedby="date"
            />
          </li>
          <li>
            <Form.Select aria-label="Default select example">
              <option value="1">AM</option>
              <option value="2">PM</option>
            </Form.Select>
          </li>
          <li>
            <Form.Select aria-label="Default select example">
              <option value="1">1</option>
              <option value="2">2</option>
            </Form.Select>
          </li>
          <li>
            <Form.Select aria-label="Default select example">
              <option value="1">SS Machikae Scope</option>
              <option value="1">SS Machikae Scope</option>
              <option value="1">SS Machikae Scope</option>
              {/* <option value="2">2</option> */}
            </Form.Select>
          </li>
          <li>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleShowFilterModal}
            >
              <CiCalendarDate />
              <span className="ms-1">STX</span>
            </button>
          </li>
          <li>
            <button type="button" className="btn btn-primary" onClick={handleShowOrderModal}>
              <FaPlus />
            </button>
          </li>
        </ul>
      </div>
      <div className="topbar__right">
        <div>
          <img src="https://via.placeholder.com/50" alt="Profile" />
        </div>
        <div>
          <span>Muhammad Bilal</span>
          <span>Chakwal Station</span>
        </div>
        <div className="notification__btn">
          <button type="button">
            <span></span>
            <IoIosNotifications />
          </button>
        </div>
        <div className="logout__btn">
          <button type="button" className="btn btn-primary">
            <MdLogout />
          </button>
        </div>
      </div>
      {filterModal && (
        <FilterModal
          filterModal={filterModal}
          handleCloseFilterModal={handleCloseFilterModal}
        />
      )}
      {orderModal && (
        <OrderModal
          orderModal={orderModal}
          handleCloseOrderModal={handleCloseOrderModal}
        />
      )}
    </div>
  );
};

export default TopBar;
