import React from "react";
import { Form, Row, Col } from "react-bootstrap";

const ParkingVmiTab = () => {
  return (
    <>
      <form action="">
        <Row className="row-gap-3">
          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
            <div className="form__input">
              <Form.Label>Tank CC</Form.Label>
              <Form.Control type="text" />
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
            <div className="form__input">
              <Form.Label>Tank Group CC</Form.Label>
              <Form.Control type="text" />
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="form__input">
              <Form.Label>Number Of Sales Transaction</Form.Label>
              <Form.Control type="text" />
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="text-end">
                <button className="btn btn-primary me-2">Save</button>
                <button className="btn btn-secondary">Cancel</button>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default ParkingVmiTab;
