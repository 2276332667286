import React from "react";
import CustomerTopbar from "../../../components/CustomerTopbar";
import "../../../styles/pages.scss";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import CustomerDeliveryTab from "../../../components/customerTabs/CustomerDeliveryTab";
import CustomerSalesTab from "../../../components/customerTabs/CustomerSalesTab";
import CustomerContactTab from "../../../components/customerTabs/CustomerContactTab";

const CustomerTiming = () => {
  const customerRoutes = [
    { navLink: "/customer-address", linkName: "Address" },
    { navLink: "/customer-tanks", linkName: "Tanks" },
    { navLink: "/customer-timings", linkName: "Timings" },
  ];

  return (
    <div className="customer__wrp">
      <CustomerTopbar customerRoutes={customerRoutes} />
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className="address__wrp">
            <div className="customer__tab">
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="delivery-opening"
              >
                <Nav variant="pills" className="justify-content-start">
                  <Nav.Item>
                    <Nav.Link eventKey="delivery-opening">
                      Delivery Opening
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="sales-opening">Sales Opening</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="contact-timing">Contact Time</Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="delivery-opening">
                    <div className="tab__content">
                      <CustomerDeliveryTab />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="sales-opening">
                    <div className="tab__content">
                      <CustomerSalesTab />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="contact-timing">
                    <div className="tab__content">
                      <CustomerContactTab />
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CustomerTiming;
