import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const AddTankModal = ({ addTankModal, handleCloseTankModal }) => {
  return (
    <>
      <form action="">
        <Modal
          show={addTankModal}
          onHide={handleCloseTankModal}
          backdrop="static"
          keyboard={false}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="timing__mdoal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Tank</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="row-gap-3">
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Label>Product</Form.Label>
                <Form.Select>
                  <option value=""></option>
                </Form.Select>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Label>Code</Form.Label>
                <Form.Control type="text" />
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Label>TG</Form.Label>
                <Form.Control type="text" />
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Label>Discharge Seq</Form.Label>
                <Form.Control type="text" />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseTankModal}>
              Close
            </Button>
            <Button variant="primary">Save Changes</Button>
          </Modal.Footer>
        </Modal>
      </form>
    </>
  );
};

export default AddTankModal;
