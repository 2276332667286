import React from "react";
import CustomerTopbar from "../../../components/CustomerTopbar";
import { Col, Row, Form, Nav, Tab } from "react-bootstrap";
import TrailerCompartmentTab from "../../../components/vehicleTabs/TrailerCompartmentTab";

const Trailer = () => {
  const customerRoutes = [
    { navLink: "/vehicles", linkName: "Vehicles" },
    { navLink: "/properties", linkName: "Properties" },
    { navLink: "/tracktor", linkName: "Tracktor" },
    { navLink: "/trailer", linkName: "Trailer" },
    { navLink: "/groups", linkName: "Groups" },
  ];
  return (
    <div className="customer__wrp">
      <CustomerTopbar customerRoutes={customerRoutes} />
      <div className="address__wrp">
        <Row>
          <Col xs={12} sm={12} md={4} lg={4} xl={4}>
            <div className="address__form">
              <form action="">
                <Row className="row-gap-3">
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className="form__input">
                      <Form.Label>Trailer Code</Form.Label>
                      <Form.Control type="text" />
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className="form__input">
                      <Form.Label>Trailer Seq Number</Form.Label>
                      <Form.Control type="text" />
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className="form__input">
                      <Form.Label>Scope</Form.Label>
                      <Form.Control type="text" />
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className="form__input">
                      <Form.Label>Trailer Volume</Form.Label>
                      <Form.Control type="text" />
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className="form__input">
                      <Form.Label>Payload</Form.Label>
                      <Form.Control type="text" />
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className="form__input">
                      <Form.Label>Trailer Tare Weight</Form.Label>
                      <Form.Control type="text" />
                    </div>
                  </Col>
                </Row>
              </form>
            </div>
          </Col>
          <Col xs={12} sm={12} md={8} lg={8} xl={8}>
            <div className="customer__tab">
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="compartment"
              >
                <Nav variant="pills" className="justify-content-start">
                  <Nav.Item>
                    <Nav.Link eventKey="compartment">Compartment</Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="compartment">
                    <div className="tab__content">
                      <TrailerCompartmentTab />
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Trailer;
