import React from "react";
import DataTable from "react-data-table-component";

const CustomerRatiosTab = () => {
  const data = [
    {
      avg_sale: "Start Time",
      mon: "0.00",
      tue: "0.00",
      wed: "0.00",
      thu: "0.00",
      fri: "0.00",
      sat: "0.00",
      sun: "0.00",
      total: "0.00",
    },
    // Add more rows as needed
  ];

  const columns = [
    {
      name: "Average Daily Sales",
      selector: (row) => row.avg_sale,
      sortable: true,
    },
    {
      name: "Mon",
      selector: (row) => row.mon,
      sortable: true,
    },
    {
      name: "Tue",
      selector: (row) => row.tue,
      sortable: true,
    },
    {
      name: "Wed",
      selector: (row) => row.wed,
      sortable: true,
    },
    {
      name: "Thu",
      selector: (row) => row.thu,
      sortable: true,
    },
    {
      name: "Fri",
      selector: (row) => row.fri,
      sortable: true,
    },
    {
      name: "Sat",
      selector: (row) => row.sat,
      sortable: true,
    },
    {
      name: "Sun",
      selector: (row) => row.sun,
      sortable: true,
    },
    {
      name: "Total",
      selector: (row) => row.total,
      sortable: true,
    },
  ];
  return (
    <>
      <div className="d-flex align-items-center justify-content-between my-3">
        <div>
          <p className="fw-bolder mb-0">
            AV Sales{" "}
            <span className="fw-normal d-inline-block ms-2">
              01010001 00 00 00
            </span>
          </p>
        </div>
        <div>
          <p className="fw-bolder mb-0">
            Infra Week{" "}
            <span className="fw-normal d-inline-block ms-2">
              01010001 00 00 00
            </span>
          </p>
        </div>
        <div>
          <p className="fw-bolder mb-0">
            Infra Day
            <span className="fw-normal d-inline-block ms-2">
              01010001 00 00 00
            </span>
          </p>
        </div>
      </div>
      <DataTable columns={columns} data={data} pagination />
    </>
  );
};

export default CustomerRatiosTab;
