import React from "react";
import "../styles/sidebar.scss";
import { Link } from "react-router-dom";


const Sidebar = () => {
  return (
    <div className="sidebar">
      <ul>
        <li>
            <Link to='/customer-address'>C</Link>
        </li>
        <li>
            <Link to='/depot-address'>D</Link>
        </li>
        <li>
            <Link to='/parking-address'>P</Link>
        </li>
        <li>
            <Link to='/vehicles'>V</Link>
        </li>
        {/* <li>
            <Link to=''>M</Link>
        </li>
        <li>
            <Link to=''>SWT</Link>
        </li>
        <li>
            <Link to=''>N</Link>
        </li>
        <li>
            <Link to=''>HM</Link>
        </li>
        <li>
            <Link to=''>T</Link>
        </li> */}
      </ul>
    </div>
  );
};

export default Sidebar;
