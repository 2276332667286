function decodeOrders(text) {
  const lines = text.split("\n");
  const orders = [];
  let currentOrder = null;
  const firstLine = text.split("\n")[0];

  // Extract the "379" from the first line
  const fname = firstLine.substring(8, 12);

  for (let i = 3; i < lines.length; i += 7) {
    // console.log(lines[i]);

    if (isStartOfOrder(lines[i]) == true && isEndOfOrder(lines[i]) == true) {
      if (currentOrder !== null) {
        orders.push(currentOrder);
      }

      currentOrder = {
        file_name: fname,
        shipTo: lines[i].substring(6, 15).trim(),
        customerName: lines[i].substring(16, lines[i].lastIndexOf("##")).trim(),
        address: lines[i + 1].substring(0, 36).trim(),
        city: lines[i + 1].substring(36).trim(),
        orderId: lines[i + 2].substring(2, 13).trim(),
        dispatchNumber: lines[i + 2].substring(67, 74).trim(),
        unknownValues: [
          lines[i + 3].substring(10, 12).trim(),
          lines[i + 3].substring(36, 39).trim(),
          lines[i + 3].substring(40, 42).trim(),
        ],
        date: lines[i + 4].substring(72).trim(),
        date2: lines[i + 5].substring(0, 6).trim(),
        dispatchNumber2: lines[i + 5].substring(9, 15).trim(),
        unknownValue: lines[i + 5].substring(20, 22).trim(),
        minQuantity: lines[i + 6].substring(6, 13).trim(),
        maxQty: lines[i + 6].substring(14, 19).trim(),
        target: lines[i + 6].substring(20, 25).trim(),
        materialCode: lines[i + 6].substring(68).trim(),
      };
    }
  }

  if (currentOrder !== null) {
    orders.push(currentOrder);
  }

  return orders;
}

function isStartOfOrder(line) {
  if (line.length > 6 && line.substring(0, 6) === "######") {
    // console.log("Start " + true);
    return true;
  } else {
    return false;
  }
}

function isEndOfOrder(line) {
  // console.log(line.length);
  line = line.slice(-3);
  // line = line.join("");
  // console.log(line)
  if (line.includes("##")) {
    // console.log("End " + true)
    return true;
  } else {
    return false;
  }
  // return line.length > 2 && line.substring(line.length - 2) === '##';
}

function formatDate(dateStr) {
  // Extract the components of the date string
  const day = dateStr.substring(0, 2);
  const month = dateStr.substring(2, 4);
  const year = "20" + dateStr.substring(4, 6);

  // Create a Date object
  const date = new Date(year, month - 1, day);

  // Options for formatting the date
  const options = { year: "numeric", month: "long", day: "numeric" };

  // Format the date
  return date.toLocaleDateString("en-US", options);
}

export { decodeOrders, formatDate };
