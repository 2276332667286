import React from "react";
import DataTable from "react-data-table-component";

const data = [
  { id: 1, name: "John Doe", age: 25, role: "Developer" },
  { id: 2, name: "Jane Smith", age: 30, role: "Designer" },
  { id: 3, name: "Sam Johnson", age: 28, role: "Manager" },
  { id: 4, name: "Chris Lee", age: 35, role: "Developer" },
];

const OrderTableTab = () => {
  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Age",
      selector: (row) => row.age,
      sortable: true,
    },
    {
      name: "Role",
      selector: (row) => row.role,
      sortable: true,
    },
  ];

  return (
    <div style={{ marginTop: "20px" }}>
      <DataTable
        title="Orders Table"
        columns={columns}
        data={data}
        pagination
        highlightOnHover
        fixedHeader
        fixedHeaderScrollHeight="400px"
        subHeader
        subHeaderComponent={
          <input
            type="text"
            placeholder="Search..."
            onChange={(e) => console.log("Search value:", e.target.value)}
            style={{
              width: "200px",
              padding: "8px",
              fontSize: "1rem",
              marginBottom: "10px",
            }}
          />
        }
      />
    </div>
  );
};

export default OrderTableTab;
