import React, { useState, useMemo } from "react";
import DataTable from "react-data-table-component";
import { IoIosAdd } from "react-icons/io";
import AddContactTimeModal from "../modals/AddContactTimeModal";

const fakeUsers = [
  {
    id: 1,
    s_no: "1",
    start_time: "11:47am",
    end_time: "10:00am",
    contact_time: "12:50pm",
    edit: "Edit",
  },
];

// Define columns
const columns = [
  {
    name: "S.No",
    selector: (row) => row.s_no,
    sortable: true,
  },
  {
    name: "Start Time",
    selector: (row) => row.start_time,
  },
  {
    name: "End Time",
    selector: (row) => row.end_time,
    sortable: true,
  },
  {
    name: "Contact Time",
    selector: (row) => row.contact_time,
    sortable: true,
  },
  {
    name: "Edit",
    selector: (row) => row.edit,
    sortable: true,
  },
];

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
    <input
      type="text"
      placeholder="Search..."
      value={filterText}
      onChange={onFilter}
      style={{ padding: "5px", fontSize: "14px" }}
    />
    <button onClick={onClear} className="btn btn-primary">
      Clear
    </button>
  </div>
);

const ParkingContactTab = () => {
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [addContactTimeModal, setAddContactTimeModal] = useState(false);

  const handleCloseContactTimeModal = () => setAddContactTimeModal(false);
  const handleShowContactTimeModal = () => setAddContactTimeModal(true);

  const filteredItems = fakeUsers.filter(
    (item) =>
      item.start_time &&
      item.start_time.toLowerCase().includes(filterText.toLowerCase())
  );

  // Memoize the sub-header component
  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <>
      <div className="address__table">
        <div className="table__modal__btn mb-3">
          <button
            className="btn btn-primary"
            type="button"
            onClick={handleShowContactTimeModal}
          >
            <IoIosAdd /> Add Contact Time
          </button>
        </div>
        <DataTable
          columns={columns}
          data={filteredItems}
          pagination
          paginationResetDefaultPage={resetPaginationToggle} // Reset pagination to page 1
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          selectableRows
          persistTableHead
        />
      </div>
      {addContactTimeModal && (
        <AddContactTimeModal
          handleCloseContactTimeModal={handleCloseContactTimeModal}
          addContactTimeModal={addContactTimeModal}
        />
      )}
    </>
  );
};

export default ParkingContactTab;
