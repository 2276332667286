import React from "react";
import { Nav, Tab } from "react-bootstrap";
import LoadingTab from "./LoadingTab";
import TaskTab from "./TaskTab";

const TTab = () => {
  return (
    <div className="t-tab">
      <div className="import__inner__tab">
        <Tab.Container id="left-tabs-example" defaultActiveKey="task">
          <Nav variant="pills" className="justify-content-start">
            <Nav.Item>
              <Nav.Link eventKey="task">Task</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="loading">Loading</Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="task">
                <TaskTab />
            </Tab.Pane>
            <Tab.Pane eventKey="loading">
                <LoadingTab />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </div>
  );
};

export default TTab;
