import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./app/auth/Login";
import Dashboard from "./app/pages/Dashboard";
import AuthLayout from "./layouts/AuthLayout";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/main.scss";
import NotFoundPage from "./app/pages/NotFound";
import CustomerAddress from "./app/pages/customers/CustomerAddress";
import CustomerTank from "./app/pages/customers/CustomerTank";
import CustomerTiming from "./app/pages/customers/CustomerTiming";
import DepotAddress from "./app/pages/depot/DepotAddress";
import DepotTanks from "./app/pages/depot/DepotTanks";
import DepotTiming from "./app/pages/depot/DepotTiming";
import ParkingAddress from "./app/pages/parking/ParkingAddress";
import ParkingTank from "./app/pages/parking/ParkingTank";
import ParkingTiming from "./app/pages/parking/ParkingTiming";
import Vehicles from "./app/pages/vehicles/Vehicles";
import Properties from "./app/pages/vehicles/Properties";
import Tracktor from "./app/pages/vehicles/Tracktor";
import Trailer from "./app/pages/vehicles/Trailer";
import Groups from "./app/pages/vehicles/Groups";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

// Initialize React Query Client
const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Routes>
          {/* Home page route */}
          <Route path="/" element={<Login />} />

          {/* Protected Dashboard route wrapped in AuthLayout */}
          <Route
            path="/dashboard"
            element={
              <AuthLayout>
                <Dashboard />
              </AuthLayout>
            }
          />
          <Route
            path="/customer-address"
            element={
              <AuthLayout>
                <CustomerAddress />
              </AuthLayout>
            }
          />
          <Route
            path="/customer-tanks"
            element={
              <AuthLayout>
                <CustomerTank />
              </AuthLayout>
            }
          />
          <Route
            path="/customer-timings"
            element={
              <AuthLayout>
                <CustomerTiming />
              </AuthLayout>
            }
          />
          <Route
            path="/depot-address"
            element={
              <AuthLayout>
                <DepotAddress />
              </AuthLayout>
            }
          />
          <Route
            path="/depot-tanks"
            element={
              <AuthLayout>
                <DepotTanks />
              </AuthLayout>
            }
          />
          <Route
            path="/depot-timings"
            element={
              <AuthLayout>
                <DepotTiming />
              </AuthLayout>
            }
          />
          <Route
            path="/parking-address"
            element={
              <AuthLayout>
                <ParkingAddress />
              </AuthLayout>
            }
          />
          <Route
            path="/parking-tanks"
            element={
              <AuthLayout>
                <ParkingTank />
              </AuthLayout>
            }
          />
          <Route
            path="/parking-timings"
            element={
              <AuthLayout>
                <ParkingTiming />
              </AuthLayout>
            }
          />
          <Route
            path="/vehicles"
            element={
              <AuthLayout>
                <Vehicles />
              </AuthLayout>
            }
          />
          <Route
            path="/properties"
            element={
              <AuthLayout>
                <Properties />
              </AuthLayout>
            }
          />
          <Route
            path="/tracktor"
            element={
              <AuthLayout>
                <Tracktor />
              </AuthLayout>
            }
          />
          <Route
            path="/trailer"
            element={
              <AuthLayout>
                <Trailer />
              </AuthLayout>
            }
          />
          <Route
            path="/groups"
            element={
              <AuthLayout>
                <Groups />
              </AuthLayout>
            }
          />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
