import React, { useState } from "react";
import CustomerTopbar from "../../../components/CustomerTopbar";
import "../../../styles/pages.scss";
import { Col, Row, Nav, Tab } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { IoIosAdd } from "react-icons/io";
import AddTankModal from "../../../components/modals/AddTankModal";
import DepotPropertiesTab from "../../../components/depotTabs/DepotPropertiesTab";
import DepotRatiosTab from "../../../components/depotTabs/DepotRatiosTab";
import DepotVmiTab from "../../../components/depotTabs/DepotVmiTab";
import DepotLeadTimeTab from "../../../components/depotTabs/DepotLeadTimeTab";
import DepotTimeStampTab from "../../../components/depotTabs/DepotTimeStampTab";

const data = [
  {
    id: 1,
    s_no: "1",
    code: "123",
    tg: "123",
    product: "123",
    discharge: "123",
    active: "Active",
    edit: "Edit",
  },
  {
    id: 2,
    s_no: "1",
    code: "123",
    tg: "123",
    product: "123",
    discharge: "123",
    active: "Active",
    edit: "Edit",
  },
  {
    id: 3,
    s_no: "1",
    code: "123",
    tg: "123",
    product: "123",
    discharge: "123",
    active: "Active",
    edit: "Edit",
  },
  // Add more rows as needed
];

const columns = [
  {
    name: "S.No",
    selector: (row) => row.s_no,
    sortable: true,
  },
  {
    name: "Code",
    selector: (row) => row.code,
    sortable: true,
  },
  {
    name: "TG",
    selector: (row) => row.tg,
    sortable: true,
  },
  {
    name: "Product",
    selector: (row) => row.product,
    sortable: true,
  },
  {
    name: "Discharge",
    selector: (row) => row.discharge,
    sortable: true,
  },
  {
    name: "Active",
    selector: (row) => row.active,
    sortable: true,
  },
  {
    name: "Edit",
    selector: (row) => row.edit,
    sortable: true,
  },
];

const DepotTanks = () => {
  const [addTankModal, setAddTankModal] = useState(false);

  const handleCloseTankModal = () => setAddTankModal(false);
  const handleShowTankModal = () => setAddTankModal(true);

  const customerRoutes = [
    { navLink: "/depot-address", linkName: "Address" },
    { navLink: "/depot-tanks", linkName: "Tanks" },
    { navLink: "/depot-timings", linkName: "Timings" },
  ];

  return (
    <div className="customer__wrp">
      <CustomerTopbar customerRoutes={customerRoutes} />
      <Row>
        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
          <div className="address__wrp">
            <div className="address__table">
              <div className="table__modal__btn mb-3">
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={handleShowTankModal}
                >
                  <IoIosAdd /> Add Tank
                </button>
              </div>
              <DataTable columns={columns} data={data} pagination />
            </div>
          </div>
        </Col>
        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
          <div className="address__wrp">
            <div className="customer__tab">
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="properties"
              >
                <Nav variant="pills" className="justify-content-start">
                  <Nav.Item>
                    <Nav.Link eventKey="properties">Properties</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="ratios">Ratios</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="vmi">VMI</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="lead-time">Lead Time</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="time-stamp">Time Stamp</Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="properties">
                    <div className="tab__content">
                      <DepotPropertiesTab />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="ratios">
                    <div className="tab__content">
                      <DepotRatiosTab />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="vmi">
                    <div className="tab__content">
                      <DepotVmiTab />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="lead-time">
                    <div className="tab__content">
                      <DepotLeadTimeTab />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="time-stamp">
                    <div className="tab__content">
                      <DepotTimeStampTab />
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        </Col>
      </Row>
      {addTankModal && (
        <AddTankModal
          addTankModal={addTankModal}
          handleCloseTankModal={handleCloseTankModal}
        />
      )}
    </div>
  );
};

export default DepotTanks;
