import React from "react";
import { NavLink } from "react-router-dom";

const CustomerTopbar = ({ customerRoutes }) => {
  console.log(customerRoutes);

  return (
    <div className="customer__topbar">
      <ul>
        {customerRoutes.map((item) => (
          <li key={item.linkName}>
            <NavLink
              to={item.navLink}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              {item.linkName}
            </NavLink>
          </li>
        ))}
        {/* <li>
            <NavLink to='/customer-routes' activeClassName='active'>Routes</NavLink>
        </li>
        <li>
            <NavLink to='/customer-status' activeClassName='active'>Status</NavLink>
        </li>
        <li>
            <NavLink to='/customer-events' activeClassName='active'>Events</NavLink>
        </li> */}
      </ul>
    </div>
  );
};

export default CustomerTopbar;
