import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const AddVehiclesModal = ({ vehicleModal, handleCloseVehicleModal }) => {
  return (
    <>
      <form action="">
        <Modal
          show={vehicleModal}
          onHide={handleCloseVehicleModal}
          backdrop="static"
          keyboard={false}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="timing__mdoal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Configured Vehicle</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="row-gap-3 align-items-end">
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Label>Tracktor Code</Form.Label>
                <Form.Control type="text" />
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Label>CV</Form.Label>
                <Form.Control type="text" />
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Label>Tracktor</Form.Label>
                <Form.Control type="text" />
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Label>Trailer</Form.Label>
                <Form.Control type="text" />
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Label>Scope</Form.Label>
                <Form.Select>
                  <option value=""></option>
                </Form.Select>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Label>Trailer Payload</Form.Label>
                <Form.Control type="text" />
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Label>Gsap Haulier </Form.Label>
                <Form.Select>
                  <option value=""></option>
                </Form.Select>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Label>User Field</Form.Label>
                <Form.Control type="text" />
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Label>Trailer Size </Form.Label>
                <Form.Select>
                  <option value=""></option>
                </Form.Select>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Label>Vehicle Group</Form.Label>
                <Form.Select>
                  <option value=""></option>
                </Form.Select>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Label>MS/ALU</Form.Label>
                <Form.Select>
                  <option value=""></option>
                </Form.Select>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <Form.Check type="checkbox" label="Is Rigid" />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseVehicleModal}>
              Close
            </Button>
            <Button variant="primary">Save Changes</Button>
          </Modal.Footer>
        </Modal>
      </form>
    </>
  );
};

export default AddVehiclesModal;
