import React from "react";
import { Form } from "react-bootstrap";
import { IoIosSettings } from "react-icons/io";
import { FaCalendarAlt, FaCheck } from "react-icons/fa";
import { LuFileSpreadsheet } from "react-icons/lu";
import { CiShare2 } from "react-icons/ci";

const SendTab = () => {
  return (
    <div className="send__tab">
      <div className="vmi__tab_wrapper" style={{ padding: "10px" }}>
        <div style={{ borderBottom: "1px solid #ddd" }}>
          <div className="heading">
            <h3>Select Shift Dates</h3>
          </div>
          <div
            className="d-flex align-items-center gap-3"
            style={{ padding: "10px 0" }}
          >
            <div>
              <Form.Check type="radio" label="Select Start Shift" />
            </div>
            <div>
              <Form.Control type="date" placeholder="name@example.com" />
            </div>
            <div>
              <Form.Select>
                <option value="">AM</option>
                <option value="">PM</option>
              </Form.Select>
            </div>
          </div>
          <div
            className="d-flex align-items-center gap-3"
            style={{ padding: "10px 0" }}
          >
            <div>
              <Form.Check type="radio" label="Select End Shift" />
            </div>
            <div>
              <Form.Control type="date" placeholder="name@example.com" />
            </div>
            <div>
              <Form.Select>
                <option value="">AM</option>
                <option value="">PM</option>
              </Form.Select>
            </div>
          </div>
          <div
            className="d-flex align-items-center gap-3"
            style={{ padding: "10px 0" }}
          >
            <div>
              <Form.Check type="radio" label="Use Trip Times?" />
            </div>
          </div>
          <div
            className="d-flex align-items-center gap-4"
            style={{ padding: "10px 0" }}
          >
            <div>
              <Form.Label>From Date</Form.Label>
              <div className="d-flex align-items-center gap-2">
                <Form.Control type="date" placeholder="name@example.com" />
                <Form.Control type="time" placeholder="name@example.com" />
              </div>
            </div>
            <div>
              <Form.Label>To Date</Form.Label>
              <div className="d-flex align-items-center gap-2">
                <Form.Control type="date" placeholder="name@example.com" />
                <Form.Control type="time" placeholder="name@example.com" />
              </div>
            </div>
          </div>
          <div
            className="d-flex align-items-center gap-3"
            style={{ padding: "10px 0" }}
          >
            <div>
              <Form.Check
                type="radio"
                label='Only send trips with status "Send Me"?'
              />
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center gap-1">
          <div>
            <button
              className="btn btn-primary"
              type="button"
              style={{ borderRadius: "0" }}
            >
              Pre Send
            </button>
          </div>
          <div>
            <button
              className="btn btn-primary"
              type="button"
              style={{ borderRadius: "0" }}
            >
              Multi Visit
            </button>
          </div>
          <div>
            <button
              className="btn btn-primary"
              type="button"
              style={{ borderRadius: "0" }}
            >
              Cut Report
            </button>
          </div>
          <div>
            <button
              className="btn btn-primary"
              type="button"
              style={{ borderRadius: "0" }}
            >
              Send
            </button>
          </div>
          <div>
            <button
              className="btn btn-primary"
              type="button"
              style={{ borderRadius: "0" }}
            >
              Post Send
            </button>
          </div>
          <div>
            <button
              className="btn btn-primary"
              type="button"
              style={{ borderRadius: "0" }}
            >
              <IoIosSettings />
            </button>
          </div>
        </div>
        <div
          className="d-flex align-items-center justify-content-between mt-3"
          style={{ padding: "10px 0" }}
        >
          <div className="d-flex align-items-center gap-1">
            <Form.Label className="mb-0">Trips to Send:</Form.Label>
            <Form.Control type="text" style={{ width: "100px" }} />
          </div>
          <div className="d-flex align-items-center gap-2">
            <div>
              <button type="button" className="btn btn-primary">
                <FaCalendarAlt /> Calculate Trip Count
              </button>
            </div>
            <div>
              <button type="button" className="btn btn-primary">
                <FaCheck /> Checked Failed Trips
              </button>
            </div>
            <div>
              <button type="button" className="btn btn-primary">
                <LuFileSpreadsheet /> Recent 114 Files
              </button>
            </div>
          </div>
        </div>
        <div
          className="d-flex align-items-center justify-content-between"
          style={{ padding: "10px 0" }}
        >
          <div className="d-flex align-items-center gap-1 w-100">
            <Form.Label className="mb-0">Filename:</Form.Label>
            <Form.Control type="text" style={{ width: "80%" }} />
          </div>
        </div>
        <div
          className="d-flex align-items-center justify-content-between"
          style={{ padding: "10px 0", width: "80%" }}
        >
          <div className="w-50">
            <Form.Check type="checkbox" label="Create 114 Files?" />
          </div>
          <div className="w-50">
            <Form.Check type="radio" label="Send to Server" />
          </div>
        </div>
        <div
          className="d-flex align-items-center justify-content-between"
          style={{ padding: "10px 0", width: "80%" }}
        >
          <div className="w-50">
            <Form.Check type="checkbox" label="Create Cost to Serve Files?" />
          </div>
          <div className="w-50">
            <Form.Check type="radio" label="Download File in Local" />
          </div>
        </div>
        <div
          className="d-flex align-items-center gap-3"
          style={{ padding: "10px 0"}}
        >
          <div>
            <Form.Check type="checkbox" label="Create CVV File?" />
          </div>
          <div>
            <Form.Check type="radio" label="All" />
          </div>
          <div>
            <Form.Check type="radio" label="New/Change" />
          </div>
          <div>
            <button type="button" className="btn btn-primary"><CiShare2 /> Send</button>
          </div>
        </div>
        <div className="heading" style={{ padding: "10px 0"}}></div>
        <div
          className="d-flex align-items-center gap-3"
          style={{ padding: "10px 0"}}
        >
          <div>
            <Form.Check type="checkbox" label="CVV Extract" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendTab;
