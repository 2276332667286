import React from 'react'

const DepotLeadTimeTab = () => {
  return (
    <div className='customer-lead-time'>
      <table className='table'>
        <thead>
          <tr>
            <th>SNO</th>
            <th>Product Name</th>
            <th>Lead Time</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>PMG</td>
            <td>9:00</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default DepotLeadTimeTab
