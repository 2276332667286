import React from "react";
import { Form, Row, Col } from "react-bootstrap";

const DepotTimeStampTab = () => {
  return (
    <>
      <form action="">
        <Row className="row-gap-3">
          <Col xs={12} sm={12} md={4} lg={4} xl={4}>
            <div className="form__input">
              <Form.Label>Target(L)</Form.Label>
              <Form.Control type="text" />
            </div>
          </Col>
          <Col xs={12} sm={12} md={4} lg={4} xl={4}>
            <div className="form__input">
              <Form.Label>Target (Days)</Form.Label>
              <Form.Control type="text" />
            </div>
          </Col>
          <Col xs={12} sm={12} md={4} lg={4} xl={4}>
            <div className="form__input">
              <Form.Label>AV Sales</Form.Label>
              <Form.Control type="text" />
            </div>
          </Col>
          <Col xs={12} sm={12} md={4} lg={4} xl={4}>
            <div className="form__input">
              <Form.Label>Dead (L)</Form.Label>
              <Form.Control type="text" />
            </div>
          </Col>
          <Col xs={12} sm={12} md={4} lg={4} xl={4}>
            <div className="form__input">
              <Form.Label>Buffer (Days)</Form.Label>
              <Form.Control type="text" />
            </div>
          </Col>
          <Col xs={12} sm={12} md={4} lg={4} xl={4}>
            <div className="form__input">
              <Form.Label>Infra Week</Form.Label>
              <Form.Control type="text" />
            </div>
          </Col>
          <Col xs={12} sm={12} md={4} lg={4} xl={4}>
            <div className="form__input">
              <Form.Label>Buffer (L)</Form.Label>
              <Form.Control type="text" />
            </div>
          </Col>
          <Col xs={12} sm={12} md={4} lg={4} xl={4}>
            <div className="form__input">
              <Form.Label>Min Duration (Min)</Form.Label>
              <Form.Control type="text" />
            </div>
          </Col>
          <Col xs={12} sm={12} md={4} lg={4} xl={4}>
            <div className="form__input">
              <Form.Label>Infra Day</Form.Label>
              <Form.Control type="text" />
            </div>
          </Col>
          <Col xs={12} sm={12} md={4} lg={4} xl={4}>
            <div className="form__input">
              <Form.Label>Last Scheduled Date</Form.Label>
              <Form.Control type="text" />
            </div>
          </Col>
          <Col xs={12} sm={12} md={4} lg={4} xl={4}>
            <div className="form__input">
              <Form.Label>Max Order Reduction (%)</Form.Label>
              <Form.Control type="text" />
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default DepotTimeStampTab;
