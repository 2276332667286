import React from "react";
import { Nav, Tab } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const AcsImportModal = ({ importModal, handleCloseImportModal }) => {
  return (
    <>
      <Modal
        show={importModal}
        onHide={handleCloseImportModal}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>ACS import Modal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="my-3">
            <p>
              Successful Orders <span>1</span>
            </p>
          </div>
          <div className="my-3">
            <p>
              Failed Orders <span>1</span>
            </p>
          </div>
          <div className="my-3">
            <p>
              Total Orders <span>1</span>
            </p>
          </div>
          <div className="my-3">
            <Tab.Container id="left-tabs-example" defaultActiveKey="success">
              <Nav variant="pills" className="justify-content-start">
                <Nav.Item>
                  <Nav.Link eventKey="success">Successful Orders</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="failed">Failed Orders</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="success">
                  <div className="order__table my-3">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>ACS No.Ship</th>
                          <th>ToCustomer</th>
                          <th>Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>THU</td>
                          <td>14/3/2024 00:01</td>
                          <td>THU</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="failed"></Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseImportModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AcsImportModal;
