import React from "react";

const TaskTab = () => {
  return <div className="task__tab">
    <div className="task__tab__table">
        <table className="table">
            <thead>
                <tr>
                    <th>Visit</th>
                    <th>Event</th>
                    <th>Task Seq</th>
                    <th>Task Type</th>
                    <th>Errors</th>
                    <th>Task Start</th>
                    <th>Min</th>
                    <th>Km</th>
                    <th>Location Code</th>
                    <th>Location</th>
                    <th>Product Code</th>
                    <th>Product Volume</th>
                    <th>Product Name</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>1</td>
                    <td>Locker</td>
                    <td>1</td>
                    <td>Locker</td>
                    <td>1</td>
                    <td>10/11/2024 04:09 pm</td>
                    <td>238</td>
                    <td>317</td>
                    <td>254</td>
                    <td>MHK</td>
                    <td>12345567</td>
                    <td>8000</td>
                    <td>SUPER C</td>
                </tr>
                <tr>
                    <td>1</td>
                    <td>Locker</td>
                    <td>1</td>
                    <td>Locker</td>
                    <td>1</td>
                    <td>10/11/2024 04:09 pm</td>
                    <td>238</td>
                    <td>317</td>
                    <td>254</td>
                    <td>MHK</td>
                    <td>12345567</td>
                    <td>8000</td>
                    <td>SUPER C</td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Total:</td>
                    <td></td>
                    <td></td>
                    <td>1826 ~ 30:26</td>
                    <td>713</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>40000</td>
                    <td></td>
                </tr>
            </tbody>
        </table>
    </div>
    <div className="task__tab__table trip__error__table">
        <h6 className="mt-4 mb-3">Trip Errors</h6>
        <table className="table">
            <thead>
                <tr>
                    <th>Severity</th>
                    <th>Code</th>
                    <th>Description</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>4</td>
                    <td>19</td>
                    <td>Exceeds Vehicle Shift Lenght</td>
                </tr>
            </tbody>
        </table>
    </div>
  </div>;
};

export default TaskTab;
