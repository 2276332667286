import React from "react";
import { IoIosAdd } from "react-icons/io";

const LoadingTab = () => {
  return (
    <div className="loading__tab">
      <div className="loading__tab__table">
        <div className="heading d-flex align-items-center gap-4 mb-3">
          <h6 className="mb-0">Order</h6>
          <button className="btn btn-primary" type="button">
            <IoIosAdd /> Show Sub Totals
          </button>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th>Seq</th>
              <th>Customer</th>
              <th>Product</th>
              <th>Tank</th>
              <th>Min</th>
              <th>Target</th>
              <th>Max</th>
              <th>Loaded</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>NEELUM VALLEY FILLING STATION</td>
              <td>HSD C</td>
              <td></td>
              <td>
                <input type="text" />
              </td>
              <td>
                <input type="text" />
              </td>
              <td>
                <input type="text" />
              </td>
              <td>5000</td>
            </tr>
            <tr>
              <td>1</td>
              <td>NEELUM VALLEY FILLING STATION</td>
              <td>HSD C</td>
              <td></td>
              <td>
                <input type="text" />
              </td>
              <td>
                <input type="text" />
              </td>
              <td>
                <input type="text" />
              </td>
              <td>5000</td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td>
                <p>Grand Total</p>
              </td>
              <td><input type="text" /></td>
              <td><input type="text" /></td>
              <td><input type="text" /></td>
              <td><p>20000</p></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="loading__tab__table mt-5">
        <div className="heading">
            <h6>Details</h6>
        </div>
        <table className="table">
            <tbody>
                <tr>
                    <td>Trailer</td>
                    <td>1</td>
                    <td>1</td>
                    <td>1</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>Compartment</td>
                    <td>1</td>
                    <td>2</td>
                    <td>3</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>Product</td>
                    <td>HSD C</td>
                    <td>SUPER C</td>
                    <td>SUPER C</td>
                    <td>Sum Val</td>
                    <td>Max Val</td>
                </tr>
                <tr>
                    <td>Total(WGT)</td>
                    <td>4200</td>
                    <td>7400</td>
                    <td>3700</td>
                    <td>15300</td>
                    <td>17170</td>
                </tr>
            </tbody>
        </table>
      </div>
      <div className="loading__tab__table mt-5">
        <div className="heading">
            <h6>Errors</h6>
        </div>
        <table className="table">
            <thead>
                <tr>
                    <th>Trailer</th>
                    <th>Compartment</th>
                    <th>Order Number</th>
                    <th>Error Message</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Outside Orders timewindow-Early Delivery</td>
                </tr>
            </tbody>
        </table>
      </div>
    </div>
  );
};

export default LoadingTab;
