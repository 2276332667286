import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const FilterModal = ({ filterModal, handleCloseFilterModal }) => {
  return (
    <>
      <Modal
        show={filterModal}
        onHide={handleCloseFilterModal}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
            <Row className="row-gap-3">
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Label>Sort</Form.Label>
                <Form.Control type="text" />
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Label>Vehilces</Form.Label>
                <Form.Control type="text" />
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Label>Trailer Size</Form.Label>
                <Form.Select>
                    <option value=""></option>
                </Form.Select>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Label>Start Location</Form.Label>
                <Form.Select>
                    <option value=""></option>
                </Form.Select>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Label>End Location</Form.Label>
                <Form.Select>
                    <option value=""></option>
                </Form.Select>
              </Col>
            </Row>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseFilterModal}>
            Close
          </Button>
          <Button variant="primary">
            Apply
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FilterModal;
