import React from "react";
import { Form } from "react-bootstrap";

const TsdTab = () => {
  return (
    <div className="tsdTab">
      <div className="vmi__tab_wrapper" style={{ padding: "10px" }}>
        <div className="heading">
          <h3>TSD CV Details</h3>
        </div>
        <div
          className="d-flex align-items-center justify-content-between"
          style={{ padding: "10px 0" }}
        >
          <div className="d-flex align-items-center gap-2">
            <div className="d-flex align-items-center gap-1">
              <Form.Label className="mb-0">Shift:</Form.Label>
              <Form.Control type="text" />
            </div>
            <div>
              <Form.Control
                type="text"
                placeholder="AM"
                style={{ width: "50px" }}
              />
            </div>
          </div>
        </div>
        <div
          className="d-flex align-items-center gap-4"
          style={{ padding: "10px 0" }}
        >
          <div className="d-flex align-items-center gap-1">
            <Form.Label className="mb-0">Tractor:</Form.Label>
            <Form.Control type="text" />
          </div>
          <div className="d-flex align-items-center gap-1 w-50">
            <Form.Label className="mb-0">Driver:</Form.Label>
            <Form.Select className="w-100">
              <option value="">MAHMOOD</option>
            </Form.Select>
          </div>
        </div>
        <div
          className="d-flex align-items-center gap-4"
          style={{ padding: "10px 0" }}
        >
          <div className="d-flex align-items-start gap-1">
            <Form.Label className="mb-0 mt-2">Trailers:</Form.Label>
            <div className="trailer__table">
              <table className="table">
                <thead>
                  <tr>
                    <th>Trailer Sequence</th>
                    <th>Trailer Code</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>t09THM</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="d-flex align-items-start gap-1 w-100">
            <Form.Label className="mb-0">Notes:</Form.Label>
            <Form.Control as="textarea" style={{ height: "80px" }} />
          </div>
        </div>
        <div className="heading" style={{ padding: "10px 0" }}>
          <h3>TSD Times and Locations</h3>
        </div>
        <div
          className="d-flex align-items-start justify-content-between"
          style={{ padding: "10px 0" }}
        >
          <div className="w-50 px-3">
            <div>
              <h3>Start</h3>
            </div>
            <div
              className="d-flex align-items-center gap-1"
              style={{ padding: "10px 0" }}
            >
              <div>
                <span>Timestamp:</span>
              </div>
              <div>
                <Form.Control type="date" placeholder="name@example.com" />
              </div>
              <div>
                <Form.Control type="time" placeholder="name@example.com" />
              </div>
            </div>
            <div
              className="d-flex align-items-center gap-3"
              style={{ padding: "10px 0" }}
            >
              <div>
                <span>Parking Location:</span>
              </div>
              <div style={{ width: "200px" }}>
                <Form.Select>
                  <option value="">MHK</option>
                </Form.Select>
              </div>
            </div>
            <div
              className="d-flex align-items-center gap-3"
              style={{ padding: "10px 0" }}
            >
              <div className="w-50">
                <span style={{ fontSize: "14px" }}>Pre Load Depot:</span>
              </div>
              <div className="w-100">
                <Form.Select>
                  <option value="">Select Pre Load Depot</option>
                </Form.Select>
              </div>
            </div>
          </div>
          <div className="w-50 px-3">
            <div>
              <h3>End</h3>
            </div>
            <div
              className="d-flex align-items-center gap-1"
              style={{ padding: "10px 0" }}
            >
              <div>
                <span>Timestamp:</span>
              </div>
              <div>
                <Form.Control type="date" placeholder="name@example.com" />
              </div>
              <div>
                <Form.Control type="time" placeholder="name@example.com" />
              </div>
            </div>
            <div
              className="d-flex align-items-center gap-3"
              style={{ padding: "10px 0" }}
            >
              <div>
                <span>Parking Location:</span>
              </div>
              <div style={{ width: "200px" }}>
                <Form.Select>
                  <option value="">MHK</option>
                </Form.Select>
              </div>
            </div>
            <div
              className="d-flex align-items-center gap-3"
              style={{ padding: "10px 0" }}
            >
              <div className="w-50">
                <span style={{ fontSize: "14px" }}>Post Load Depot:</span>
              </div>
              <div className="w-100">
                <Form.Select>
                  <option value="">Select Post Load Depot</option>
                </Form.Select>
              </div>
            </div>
            <div
              className="d-flex align-items-center gap-3"
              style={{ padding: "10px 0" }}
            >
              <div>
                <Form.Check
                  type="checkbox"
                  label="Update Pre Load Depot on next available shift"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="heading" style={{ padding: "10px 0" }}>
          <h3>TSD Availibilty</h3>
        </div>
        <div
          className="d-flex align-items-center gap-4"
          style={{ padding: "10px 0" }}
        >
          <div className="d-flex align-items-center gap-1">
            <Form.Label className="mb-0">Availibility:</Form.Label>
            <Form.Select className="w-100">
              <option value="">Not Defined</option>
            </Form.Select>
          </div>
          <div className="d-flex align-items-center gap-1 w-50">
            <Form.Label className="mb-0">Driver:</Form.Label>
            <Form.Select className="w-100">
              <option value="">Select...</option>
            </Form.Select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TsdTab;
