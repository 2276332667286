import React from 'react'
import { Col, Row, Form } from "react-bootstrap";

const DepotPropertiesTab = () => {
  return (
    <form action="">
    <Row>
      <Col xs={12} sm={12} md={4} lg={4} xl={4}>
        <div className="form__group">
          <div className="form__header">
            <span>Status</span>
          </div>
          <div className="form_wrp">
            <Row className="row-gap-3">
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="form__input">
                  <Form.Check
                    type="checkbox"
                    label="Active"
                  />
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="form__input">
                  <Form.Label>
                    Last Scheduled Date
                  </Form.Label>
                  <Form.Control type="text" />
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="form__input">
                  <Form.Label>Average Sale</Form.Label>
                  <Form.Control type="text" />
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="form__input">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control type="text" />
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="form__input">
                  <Form.Label>Task Classifier</Form.Label>
                  <Form.Control type="text" />
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="form__input">
                  <Form.Check
                    type="checkbox"
                    label="Mark For Deletion"
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Col>
      <Col xs={12} sm={12} md={4} lg={4} xl={4}>
        <div className="form__group">
          <div className="form__header">
            <span>Product</span>
          </div>
          <div className="form_wrp">
            <Row className="row-gap-3">
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="form__input">
                  <Form.Label>Product Name</Form.Label>
                  <Form.Control type="text" />
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="form__input">
                  <Form.Label>Product Code</Form.Label>
                  <Form.Control type="text" />
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="form__input">
                  <Form.Label>OGG</Form.Label>
                  <Form.Control type="text" />
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="form__input">
                  <Form.Label>Code</Form.Label>
                  <Form.Control type="text" />
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="form__input">
                  <Form.Label>TG</Form.Label>
                  <Form.Control type="text" />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Col>
      <Col xs={12} sm={12} md={4} lg={4} xl={4}>
        <div className="form__group">
          <div className="form__header">
            <span>Dimension</span>
          </div>
          <div className="form_wrp">
            <Row className="row-gap-3">
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="form__input">
                  <Form.Label>Capacity</Form.Label>
                  <Form.Control type="text" />
                </div>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="form__input">
                  <Form.Label>Target (L)</Form.Label>
                  <Form.Control type="text" />
                </div>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="form__input">
                  <Form.Label>Target (Days)</Form.Label>
                  <Form.Control type="text" />
                </div>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="form__input">
                  <Form.Label>Dead (L)</Form.Label>
                  <Form.Control type="text" />
                </div>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="form__input">
                  <Form.Label>
                    Target In Days (L)
                  </Form.Label>
                  <Form.Control type="text" />
                </div>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="form__input">
                  <Form.Label>Buffer (L)</Form.Label>
                  <Form.Control type="text" />
                </div>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="form__input">
                  <Form.Label>Buffer (Days)</Form.Label>
                  <Form.Control type="text" />
                </div>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="form__input">
                  <Form.Label>Buffer Type (L)</Form.Label>
                  <Form.Control type="text" />
                </div>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="form__input">
                  <Form.Label>
                    Allowed Used (Days)
                  </Form.Label>
                  <Form.Control type="text" />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Col>
      <Col xs={12} sm={12} md={4} lg={4} xl={4}>
        <div className="form__group">
          <div className="form__header">
            <span>Order</span>
          </div>
          <div className="form_wrp">
            <Row className="row-gap-3">
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="form__input">
                  <Form.Label>Mix Drop Size (L)</Form.Label>
                  <Form.Control type="text" />
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="form__input">
                  <Form.Label>
                    Mix Order Size (%)
                  </Form.Label>
                  <Form.Control type="text" />
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="form__input">
                  <Form.Label>
                    Special Allowance (Min)
                  </Form.Label>
                  <Form.Control type="text" />
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="form__input">
                  <Form.Label>
                    Min Duration (Min)
                  </Form.Label>
                  <Form.Control type="text" />
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="form__input">
                  <Form.Check
                    type="checkbox"
                    label="Mark For Deletion"
                  />
                  <Form.Label>
                    %predicted Sales (ISR+)
                  </Form.Label>
                  <Form.Control type="text" />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Col>
      <Col xs={12} sm={12} md={4} lg={4} xl={4}>
        <div className="form__group">
          <div className="form__header">
            <span>Full Load Limits</span>
          </div>
          <div className="form_wrp">
            <Row className="row-gap-3">
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="form__input">
                  <Form.Label>Calculated Volume</Form.Label>
                  <Form.Control type="text" />
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="form__input">
                  <Form.Label>
                  Override Volume
                  </Form.Label>
                  <Form.Control type="text" />
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="form__input">
                  <Form.Label>
                  Calculated Weight
                  </Form.Label>
                  <Form.Control type="text" />
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="form__input">
                  <Form.Label>
                  Maximum Order Products
                  </Form.Label>
                  <Form.Control type="text" />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Col>
      <Col xs={12} sm={12} md={4} lg={4} xl={4}>
        <div className="form__group">
          <div className="form__header">
            <span>Discharge</span>
          </div>
          <div className="form_wrp">
            <Row className="row-gap-3">
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="form__input">
                  <Form.Label>Required Trailer (Product Discharge) </Form.Label>
                  <Form.Select>
                    <option value=""></option>
                  </Form.Select>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="form__input">
                  <Form.Label>
                  Required Method 
                  </Form.Label>
                  <Form.Select>
                    <option value=""></option>
                  </Form.Select>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="form__input">
                  <Form.Label>
                  Override Rate(liters/in)
                  </Form.Label>
                  <Form.Control type="text" />
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="form__input">
                  <Form.Label>
                  Trailer Number
                  </Form.Label>
                  <Form.Control type="text" />
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="text-end">
                  <button className="btn btn-primary me-2">Save</button>
                  <button className="btn btn-secondary me-2">Cancel</button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Col>
    </Row>
  </form>
  )
}

export default DepotPropertiesTab
