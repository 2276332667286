import React, { useState, useMemo } from "react";
import CustomerTopbar from "../../../components/CustomerTopbar";
import DataTable from "react-data-table-component";

const fakeUsers = [
    {
      id: 1,
      s_no: "1",
      group_functions: "Group Functions",
      group_name: "Group Name",
      information: "Information",
    },
];

// Define columns
const columns = [
  {
    name: "S.No",
    selector: (row) => row.s_no,
    sortable: true,
  },
  {
    name: "Group Functions",
    selector: (row) => row.group_functions,
    sortable: true,
  },
  {
    name: "Group Name",
    selector: (row) => row.group_name,
  },
  {
    name: "Information",
    selector: (row) => row.information,
    sortable: true,
  },
];

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
    <input
      type="text"
      placeholder="Search..."
      value={filterText}
      onChange={onFilter}
      style={{ padding: "5px", fontSize: "14px" }}
    />
    <button onClick={onClear} className="btn btn-primary">
      Clear
    </button>
  </div>
);

const Groups = () => {
    const [filterText, setFilterText] = useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  
    // Filter the data based on search input
    const filteredItems = fakeUsers.filter(
      (item) =>
        item.group_functions && item.group_functions.toLowerCase().includes(filterText.toLowerCase())
    );
  
    // Memoize the sub-header component
    const subHeaderComponentMemo = useMemo(() => {
      const handleClear = () => {
        if (filterText) {
          setResetPaginationToggle(!resetPaginationToggle);
          setFilterText("");
        }
      };
  
      return (
        <FilterComponent
          onFilter={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
        />
      );
    }, [filterText, resetPaginationToggle]);

  const customerRoutes = [
    { navLink: "/vehicles", linkName: "Vehicles" },
    { navLink: "/properties", linkName: "Properties" },
    { navLink: "/tracktor", linkName: "Tracktor" },
    { navLink: "/trailer", linkName: "Trailer" },
    { navLink: "/groups", linkName: "Groups" },
  ];
  return (
    <div className="customer__wrp">
      <CustomerTopbar customerRoutes={customerRoutes} />
      <div className="address__wrp">
        <div className="address__table">
          <DataTable
            columns={columns}
            data={filteredItems}
            pagination
            paginationResetDefaultPage={resetPaginationToggle} // Reset pagination to page 1
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            selectableRows
            persistTableHead
          />
        </div>
      </div>
    </div>
  );
};

export default Groups;
