import React, { useState, useMemo } from "react";
import CustomerTopbar from "../../../components/CustomerTopbar";
import DataTable from "react-data-table-component";
import { IoIosAdd } from "react-icons/io";
import AddVehiclesModal from "../../../components/modals/AddVehiclesModal";

const fakeUsers = [
    {
        id: 1,
        s_no: "1",
        vehicle_name: "Vehicle Name",
        tracker_code: "Tracker Code",
        tracker_reg: "Tracker Reg",
        trailer_code: "Trailer Code",
        trailer_reg: "Trailer Reg",
        scope: "Scope",
        gsap_haulier: "GSAP Haulier",
        user_field_1: "User Field 1",
        ms_alu: "MS/ALU",
        active: "Active",
        edit: "Edit",
        delete: "Delete",
    },
];

// Define columns
const columns = [
  {
    name: "S.No",
    selector: (row) => row.s_no,
    sortable: true,
  },
  {
    name: "Vehicle Name",
    selector: (row) => row.vehicle_name,
    sortable: true,
  },
  {
    name: "Tracker Code",
    selector: (row) => row.tracker_code,
  },
  {
    name: "Tracker Reg",
    selector: (row) => row.tracker_reg,
    sortable: true,
  },
  {
    name: "Trailer Code",
    selector: (row) => row.trailer_code,
    sortable: true,
  },
  {
    name: "Trailer Reg",
    selector: (row) => row.trailer_reg,
    sortable: true,
  },
  {
    name: "Scope",
    selector: (row) => row.scope,
    sortable: true,
  },
  {
    name: "GSAP Haulier",
    selector: (row) => row.gsap_haulier,
    sortable: true,
  },
  {
    name: "User Field 1",
    selector: (row) => row.user_field_1,
    sortable: true,
  },
  {
    name: "MS/ALU",
    selector: (row) => row.ms_alu,
    sortable: true,
  },
  {
    name: "Active",
    selector: (row) => row.active,
    sortable: true,
  },
  {
    name: "Edit",
    selector: (row) => row.edit,
    sortable: true,
  },
  {
    name: "Delete",
    selector: (row) => row.delete,
    sortable: true,
  },
];

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
    <input
      type="text"
      placeholder="Search..."
      value={filterText}
      onChange={onFilter}
      style={{ padding: "5px", fontSize: "14px" }}
    />
    <button onClick={onClear} className="btn btn-primary">
      Clear
    </button>
  </div>
);

const Vehicles = () => {
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [vehicleModal, setVehicleModal] = useState(false);

  const handleCloseVehicleModal = () => setVehicleModal(false);
  const handleShowVehicleModal = () => setVehicleModal(true);

  // Filter the data based on search input
  const filteredItems = fakeUsers.filter(
    (item) =>
      item.vehicle_name && item.vehicle_name.toLowerCase().includes(filterText.toLowerCase())
  );

  // Memoize the sub-header component
  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const customerRoutes = [
    { navLink: "/vehicles", linkName: "Vehicles" },
    { navLink: "/properties", linkName: "Properties" },
    { navLink: "/tracktor", linkName: "Tracktor" },
    { navLink: "/trailer", linkName: "Trailer" },
    { navLink: "/groups", linkName: "Groups" },
  ];

  return (
    <div className="customer__wrp">
      <CustomerTopbar customerRoutes={customerRoutes} />
      <div className="address__wrp">
        <div className="address__table">
          <div className="table__modal__btn mb-3">
            <button
              className="btn btn-primary"
              type="button"
              onClick={handleShowVehicleModal}
            >
              <IoIosAdd /> Add Vehicle
            </button>
          </div>
          <DataTable
            columns={columns}
            data={filteredItems}
            pagination
            paginationResetDefaultPage={resetPaginationToggle} // Reset pagination to page 1
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            selectableRows
            persistTableHead
          />
        </div>
      </div>
      {vehicleModal && <AddVehiclesModal vehicleModal={vehicleModal} handleCloseVehicleModal={handleCloseVehicleModal} />}
    </div>
  );
};

export default Vehicles;
