import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const TracktorTsdModal = ({
  tracktorTsdModal,
  handleCloseTracktorTsdModal,
}) => {
  return (
    <>
      <form action="">
        <Modal
          show={tracktorTsdModal}
          onHide={handleCloseTracktorTsdModal}
          backdrop="static"
          keyboard={false}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="timing__mdoal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Tracktor TSD</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="row-gap-3 align-items-end">
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Label>Start Day </Form.Label>
                <Form.Select>
                  <option value=""></option>
                </Form.Select>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Label>Start Time</Form.Label>
                <Form.Control type="time" />
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Label>End Day</Form.Label>
                <Form.Select>
                  <option value=""></option>
                </Form.Select>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Label>End Time</Form.Label>
                <Form.Control type="time" />
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Label>Availability</Form.Label>
                <Form.Select>
                  <option value=""></option>
                </Form.Select>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Label>Shift</Form.Label>
                <Form.Select>
                  <option value=""></option>
                </Form.Select>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Label>Start Location </Form.Label>
                <Form.Select>
                  <option value=""></option>
                </Form.Select>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Label>End Location  </Form.Label>
                <Form.Select>
                  <option value=""></option>
                </Form.Select>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Label>PreLoad Depot</Form.Label>
                <Form.Select>
                  <option value=""></option>
                </Form.Select>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Label>PostLoad Depot </Form.Label>
                <Form.Select>
                  <option value=""></option>
                </Form.Select>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Label>Driver</Form.Label>
                <Form.Select>
                  <option value=""></option>
                </Form.Select>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseTracktorTsdModal}>
              Close
            </Button>
            <Button variant="primary">Save Changes</Button>
          </Modal.Footer>
        </Modal>
      </form>
    </>
  );
};

export default TracktorTsdModal;
