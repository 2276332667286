import React from "react";
import CustomerTopbar from "../../../components/CustomerTopbar";
import { Col, Row, Form } from "react-bootstrap";

const Properties = () => {
  const customerRoutes = [
    { navLink: "/vehicles", linkName: "Vehicles" },
    { navLink: "/properties", linkName: "Properties" },
    { navLink: "/tracktor", linkName: "Tracktor" },
    { navLink: "/trailer", linkName: "Trailer" },
    { navLink: "/groups", linkName: "Groups" },
  ];
  return (
    <div className="customer__wrp">
      <CustomerTopbar customerRoutes={customerRoutes} />
      <div className="address__wrp">
        <div className="address__form">
          <form action="">
            <h4 className="mb-3">Co2 Details</h4>
            <Row className="row-gap-3 align-items-end">
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Label>Plainboard Abbreviation</Form.Label>
                <Form.Control type="text" />
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Label>Volume (L)</Form.Label>
                <Form.Control type="text" />
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Label>Volume (L)</Form.Label>
                <Form.Control type="text" />
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Label>Fuel Type</Form.Label>
                <Form.Select>
                  <option value=""></option>
                </Form.Select>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Label>Weight (WGT)</Form.Label>
                <Form.Control type="text" />
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Label>Last Schedule Date</Form.Label>
                <Form.Control type="date" />
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Label>Loading Factor</Form.Label>
                <Form.Control type="text" />
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <h4>Inter Trader Pumping factor</h4>
                <Form.Label>Front To Back</Form.Label>
                <Form.Control type="text" />
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Label>Discharge Factor</Form.Label>
                <Form.Control type="text" />
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Label>Back To Front</Form.Label>
                <Form.Control type="text" />
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Label>Products</Form.Label>
                <Form.Control type="text" />
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}></Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Check type="checkbox" label="Drop Last Trailer" />
                <Form.Check type="checkbox" label="Simultaneous Loading" />
                <Form.Check type="checkbox" label="Product Substitution" />
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="text-end">
                  <button type="button" className="btn btn-primary">
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Properties;
